.tabs{
	
	&__top{
		display: flex;
		justify-content: flex-start;
		align-items:center;
		border-top-left-radius: 8px;
		border-top-right-radius: 8px;
		border:1px solid #ecf3fe;
		border-bottom: 0;
		overflow-x: auto;
   		overflow-y: hidden;
   		scroll-snap-type: x mandatory;
   		//max-width:calc(100vw - 90px);
   		&::-webkit-scrollbar {
		  height: 5px;
		}
		 
		&::-webkit-scrollbar-track {
		  background: #ffffff;
		}
		 
		&::-webkit-scrollbar-thumb {
		  background: #ecf3fe; 
		}
	}
	
	&__panes{
		border:1px solid #ecf3fe;
		border-bottom-left-radius: 8px;
		border-bottom-right-radius: 8px;
	}
	&__pane{
		display: none;
		padding: 18px;
		
		&--active{
			display: block;
		}
	}
	
	
}
.tab-bar{
	margin-left: -18px;
	margin-right: -18px;
	display: flex;
	border-bottom: 1px solid #e8e8e8;
	padding-left: 9px;
	padding-right: 9px;
	overflow-y: hidden;
	overflow-x: auto;

	> div{
		scroll-snap-align: start;
	}
	input#tab-mobile-change + label {		
		position: absolute;
		right: 0px;
		top: 14px;
		width: 24px;
		height: 24px;
		background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='12px' height='8px'%3E%3Cpath fill-rule='evenodd' fill-opacity='1' fill='%230c2f72' opacity='1' d='M10.590,0.437 L6.000,4.818 L1.410,0.437 L0.000,1.786 L6.000,7.524 L12.000,1.786 L10.590,0.437 Z'/%3E%3C/svg%3E");
		background-size: 12px auto;
		background-position: center center;
		background-repeat: no-repeat;
		display: none;
		border: 0px;
		outline: none;
		
		&:before {
			display: none;
		}
		&:after {
			display: none;
		}
	}
	input#tab-mobile-change:checked + label {
		transform: rotate(180deg);
		
		~ div  {
			.tab {
				display: inline-flex;
			}
		}
	}
}
.tab{
	display: inline-flex;
	align-items:flex-start;
	height: 36px;
	white-space: nowrap;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	color: #2d76e5;
	border:0;
	//border-right:1px solid #ecf3fe;
	background-color: transparent;
	outline:0;
	position: relative;
	cursor: pointer;
	font-size: 16px;
	white-space: nowrap;
	scroll-snap-align: start;
	margin-left: 9px;
	margin-right: 9px;
	&:before{
		content:'';
		position: absolute;
		left: 0;
		width: 0;
		transition: width linear 0.15s;
		height: 3px;
		background-color: #2d76e5;
		bottom: -1px;
	}
	&:first-child{
		border-left-color:transparent;
	}
	&:last-child{
		
	}
	&:hover{
		color: #2d76e5;
		&:before{
			width: 100%;
		}
	}
	&--active{
		font-weight: bold;
		color: var(--primary-blue);
		position: relative;
		&:before{
			width: 100%;
			background-color: var(--primary-blue);
		}
		&:hover{
			&:before{
				background-color: #2d76e5;
			}
		}
	}
}

@media screen and (max-width:600px){

	.tab{
		font-size: 14px;
	}
}
@media screen and (max-width:479px){
	
	.tab-bar {
		flex-direction: column;
		
		div	{
			width: 100%;
			border-bottom: 1px solid #e8e8e8;
			margin-bottom: -1px;
			margin-top: -1px;
		}
		.tab {
			height: auto;
			padding-top: 13px;
			padding-bottom: 13px;
		}
		.tab:not(.tab--active) {
			display: none;
		}
		input#tab-mobile-change + label {
			display: block;
		}
	}
	
}